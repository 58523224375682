<template>
  <div class="articleList">
    <div class="container">
      <my-breadcrumb>
        <my-breadcrumb-item to="/">HOME</my-breadcrumb-item>
        <my-breadcrumb-item  :active="true" :isDisabled="true"
          >ARTICLEDETAIL</my-breadcrumb-item
        >
      </my-breadcrumb>
      <div class="content">
        <div class="title">{{ article.title }}</div>
        <div class="user">
          <!-- <div class="img">
            <img v-lazy="article.author_avatar" alt="" />
          </div> -->
          <div class="info">
            <div class="name">{{ article.author }}</div>
            <!-- <div class="decripe">{{ article.author_desc }}</div> -->
            <div class="time">{{ article.createtime }}</div>
          </div>
        </div>

        <div class="detil" v-html="article.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";
import { Article } from "@/model/Article.js";
export default {
  name: "ArticleDetail",
  data() {
    return {
      article: {},
    };
  },
  created() {
    this.getArticleDetail();
  },
  components: {
    MyBreadcrumb,
    MyBreadcrumbItem,
  },
  methods: {
    // 请求文字列表
    getArticleDetail() {
      Article.Detail({
        data: {
          article_id: this.$route.query.article_id,
        },
        succ: (res) => {
          this.article = res.article;
          console.log(res);
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.articleList {
  padding-top: 1.5rem;
  .content {
    margin-top: 1.5rem;
    background-color: white;
    padding: 1.5rem;
    .title {
      font-size: 1.5rem;
      color:var(--text-color-title);
           font-weight: 600;
           text-align: center;
      margin-bottom: 0.75rem;
    }
    .user {
      margin-top:1.25rem;
      margin-bottom:2rem;
      padding-bottom:0.75rem;
      border-bottom:1px solid var(--border-color-default);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .img {
        border-radius: 50%;
        overflow: hidden;
        margin-right:0.75rem;
        width: 4rem;
        img {
          width: 100%;
          vertical-align: bottom;
        }
      }

      .info {
        display: flex;
        padding:0.5rem 0;
        .name {
          font-size: 0.75rem;
          color:var( --text-color-default);
          margin-right:0.5rem;
          font-weight: 600;
        }

        .time {
          font-size: 0.75rem;
          color: var(--text-color-minor);
        }

        .decripe {
          font-size: 0.75rem;
          color: var(--text-color-minor);
        }
      }
    }
  }
}
</style>